<template>
  <div class="pb-2">
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="trashTableColumns"
      :create-page-url="{ name: 'home-customer-create' }"
      :is-searchable="false"
      :table-config-options="getTableConfigOptions()"
      back-to-list-path="home-customers-list"
      :is-actions="true"
      :is-delete="true"
    >
      <template #cell(batchdelete)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            icon="LLoadIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #cell(cust_name_dba)="{ data }">
        <b-link
          :to="{ name: 'home-customers-view' , params: { id: data.item.id }}"
          class="text-body text-wrap"
        >
          <feather-icon
            :icon=" data.item.verified ? 'LTickIcon' : 'LWarningIcon'"
            size="16"
          />
          {{ data.item.cust_name_dba }}
        </b-link>
      </template>
<!--      <template #cell(customer_id_3rd_party_payment_option)="{ data }">-->
<!--        {{ data.item.customer_3rd_party_payment_option ? data.item.customer_3rd_party_payment_option.cust_name_dba : '-&#45;&#45;' }}-->
<!--      </template>-->
      <template #cell(status)="{ data }">
        {{ data.item.is_active ? $t('Active') : 'Inactive' }}
      </template>

      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

// components
import LTable from '@/views/components/LTable/LTable.vue'
import {BLink, VBTooltip} from 'bootstrap-vue'
import config from '../customConfig'

export default {
  name: 'CustomerTrashList',
  components: {
    BLink,
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
        .then(
          () => {
            this.refetchData()
          },
        )
    },
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      const customerId = this.$route.params.id
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          customerId,
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    update(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
      }
    },
    getPhoneNumber(obj) {
      const data = JSON.parse(obj)

      return `${data.formatNational} ${data.cell_no_ext_val ? `Ext.${data.cell_no_ext_val}` : ''}`
    },
    getData(data, dataName) {
      const { item: { default_contact } } = data
      if (dataName === 'fullname') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.first_name} ${default_contact.last_name}` : '—'
      } if (dataName === 'cell_no') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.cell_no}` : '--—'
      }
      return '---'
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/trashList`,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const { tableColumns, trashTableColumns } = config()
    return {
      tableColumns,
      MODULE_NAME,
      trashTableColumns,
    }
  },
}
</script>
