var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.trashTableColumns,"create-page-url":{ name: 'home-customer-create' },"is-searchable":false,"table-config-options":_vm.getTableConfigOptions(),"back-to-list-path":"home-customers-list","is-actions":true,"is-delete":true},scopedSlots:_vm._u([{key:"cell(batchdelete)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",attrs:{"icon":"LLoadIcon","size":"24"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})],1)]}},{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"24"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})],1)]}},{key:"cell(cust_name_dba)",fn:function(ref){
var data = ref.data;
return [_c('b-link',{staticClass:"text-body text-wrap",attrs:{"to":{ name: 'home-customers-view' , params: { id: data.item.id }}}},[_c('feather-icon',{attrs:{"icon":data.item.verified ? 'LTickIcon' : 'LWarningIcon',"size":"16"}}),_vm._v(" "+_vm._s(data.item.cust_name_dba)+" ")],1)]}},{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active ? _vm.$t('Active') : 'Inactive')+" ")]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }